var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "report-layout", attrs: { align: "center" } },
    [
      _c(
        "div",
        { staticClass: "page" },
        [
          _c("HeaderReport", {
            attrs: {
              RadioName: _vm.parentName ? _vm.parentName : _vm.nameRadio,
              RadioLogo: _vm.logoRadio,
            },
          }),
          _c(
            "div",
            { staticClass: "md-layout", staticStyle: { padding: "0 0 0 0" } },
            [
              _c(
                "div",
                {
                  staticClass: "md-layout-item md-size-90",
                  staticStyle: { margin: "1rem auto" },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                        "align-items": "center",
                      },
                    },
                    [
                      _vm._m(0),
                      _c("div", { staticStyle: { width: "40%" } }, [
                        _c(
                          "h5",
                          {
                            staticStyle: {
                              margin: "0",
                              "text-align": "right",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v(" Período de apuração ")]
                        ),
                        _c(
                          "h3",
                          {
                            staticStyle: {
                              margin: "0",
                              "text-align": "right",
                              "font-weight": "bold",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.periodStart) +
                                " a " +
                                _vm._s(_vm.periodEnd) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "h3",
                          {
                            staticStyle: {
                              margin: "0",
                              "text-align": "right",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v(" Tópico: " + _vm._s(_vm.topic) + " ")]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "md-layout md-alignment-center-center",
              staticStyle: { height: "60%" },
            },
            [
              _c(
                "div",
                { staticClass: "md-layout-item md-size-90" },
                [
                  _c(
                    "md-card",
                    [
                      _c("md-card-header", [
                        _c(
                          "h3",
                          {
                            staticStyle: {
                              margin: "10px 0 0 5px !important",
                              "text-align": "left",
                            },
                          },
                          [_vm._v(" Estatísticas gerais ")]
                        ),
                      ]),
                      _c(
                        "md-card-content",
                        { staticClass: "md-content-dashboard" },
                        [
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              {
                                staticClass: "md-layout-item md-size-25",
                                staticStyle: { "text-align": "center" },
                              },
                              [
                                _c("h4", [_vm._v("Total de envios")]),
                                _c("h3", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatNumber")(
                                        _vm.data.totalPushesSent
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "md-layout-item md-size-25",
                                staticStyle: { "text-align": "center" },
                              },
                              [
                                _c("h4", [_vm._v("Total de aberturas")]),
                                _c("h3", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatNumber")(
                                        _vm.data.totalPushesOpened
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "md-layout-item md-size-25",
                                staticStyle: { "text-align": "center" },
                              },
                              [
                                _c("h4", [_vm._v("Taxa de abertura")]),
                                _c("h3", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.data.percentOpeningTotalPushes * 100
                                    ) + "%"
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "md-layout-item md-size-25",
                                staticStyle: { "text-align": "center" },
                              },
                              [
                                _c("h4", [_vm._v("Total de pushes")]),
                                _c("h3", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatNumber")(
                                        _vm.data.totalPushesRegistred
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "md-layout-item md-size-90" },
                [
                  _c(
                    "md-card",
                    { staticStyle: { height: "550px" } },
                    [
                      _c("md-card-header", [
                        _c(
                          "h3",
                          {
                            staticStyle: {
                              margin: "10px 0 0 5px !important",
                              "text-align": "left",
                            },
                          },
                          [_vm._v(" Gráfico de ouvintes por dia ")]
                        ),
                      ]),
                      _c(
                        "md-card-content",
                        { staticClass: "md-content-dashboard" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "md-layout",
                              staticStyle: { height: "350px" },
                            },
                            [
                              _c("div", {
                                ref: "chart",
                                staticClass:
                                  "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 row-icon-dashboard platform-data-container",
                                staticStyle: { padding: "0" },
                                attrs: {
                                  id: "chart-openings-per-category-template",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._l(this.getDatesInGroupsOf40(), function (groupOfDates, index) {
        return _c(
          "div",
          { staticClass: "page" },
          [
            _c("header", { staticClass: "second-page-header" }, [
              _c("h3", [_vm._v("Relatório de ouvintes de notificações")]),
              _c("h3", [
                _vm._v(_vm._s(_vm.periodStart) + " a " + _vm._s(_vm.periodEnd)),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "table-container md-layout-item md-size-90",
                staticStyle: { margin: "auto" },
              },
              [
                _c(
                  "h4",
                  {
                    staticClass: "md-layout-item md-size-95",
                    staticStyle: { margin: "1rem" },
                  },
                  [_vm._v("Ouvintes por dia")]
                ),
                _c(
                  "table",
                  {
                    staticClass: "md-layout-item md-size-50",
                    staticStyle: { display: "inline-block" },
                  },
                  [
                    _vm._m(1, true),
                    _vm._l(
                      groupOfDates.slice(0, Math.ceil(groupOfDates.length / 2)),
                      function (item, index) {
                        return _c(
                          "tr",
                          { key: index, staticClass: "tr_tables" },
                          [
                            _c("td", { staticClass: "td_tables" }, [
                              _vm._v(
                                _vm._s(
                                  new Date(item.date).toLocaleDateString(
                                    "pt-BR"
                                  )
                                )
                              ),
                            ]),
                            _c("td", { staticClass: "td_tables" }, [
                              _vm._v(
                                _vm._s(_vm._f("formatNumber")(item.android))
                              ),
                            ]),
                            _c("td", { staticClass: "td_tables" }, [
                              _vm._v(_vm._s(_vm._f("formatNumber")(item.ios))),
                            ]),
                            _c("td", { staticClass: "td_tables" }, [
                              _vm._v(
                                _vm._s(_vm._f("formatNumber")(item.total))
                              ),
                            ]),
                            _c("td", { staticClass: "td_tables" }, [
                              _vm._v(
                                _vm._s(_vm._f("formatNumber")(item.total))
                              ),
                            ]),
                          ]
                        )
                      }
                    ),
                  ],
                  2
                ),
                _c(
                  "table",
                  {
                    staticClass: "md-layout-item md-size-50",
                    staticStyle: { display: "inline-block" },
                  },
                  [
                    _vm._m(2, true),
                    _vm._l(
                      groupOfDates.slice(
                        Math.floor(groupOfDates.length / 2),
                        groupOfDates.length
                      ),
                      function (item, index) {
                        return _c(
                          "tr",
                          { key: index, staticClass: "tr_tables" },
                          [
                            _c("td", { staticClass: "td_tables" }, [
                              _vm._v(
                                _vm._s(
                                  new Date(item.date).toLocaleDateString(
                                    "pt-BR"
                                  )
                                )
                              ),
                            ]),
                            _c("td", { staticClass: "td_tables" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatNumber")(item.pushes_registred)
                                )
                              ),
                            ]),
                            _c("td", { staticClass: "td_tables" }, [
                              _vm._v(
                                _vm._s(_vm._f("formatNumber")(item.pushes_sent))
                              ),
                            ]),
                            _c("td", { staticClass: "td_tables" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatNumber")(item.pushes_opened)
                                )
                              ),
                            ]),
                            _c("td", { staticClass: "td_tables" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatNumber")(
                                    item.percent_openings.toFixed(2) * 100
                                  )
                                )
                              ),
                            ]),
                          ]
                        )
                      }
                    ),
                  ],
                  2
                ),
              ]
            ),
            _c("FooterReport"),
          ],
          1
        )
      }),
      _vm.radiosConsolidated.length > 1
        ? _c(
            "div",
            { staticClass: "page" },
            [
              _c("header", { staticClass: "second-page-header" }, [
                _c("h3", [_vm._v("Relatório de ouvintes de notificações")]),
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.periodStart) + " a " + _vm._s(_vm.periodEnd)
                  ),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "table-container md-layout-item md-size-90",
                  staticStyle: { margin: "auto" },
                },
                [
                  _c(
                    "h4",
                    {
                      staticClass: "md-layout-item md-size-95",
                      staticStyle: { margin: "1rem" },
                    },
                    [_vm._v("Rádios consolidadas")]
                  ),
                  _c(
                    "div",
                    { staticClass: "md-layout md-alignment-top-center" },
                    _vm._l(this.getRadiosInGroupsOf20(), function (radioGroup) {
                      return _c(
                        "table",
                        {
                          staticClass: "md-layout-item md-size-30",
                          staticStyle: { display: "inline-block" },
                        },
                        _vm._l(radioGroup, function (item, index) {
                          return _c(
                            "tr",
                            { key: index, staticClass: "tr_tables" },
                            [
                              _c("td", { staticClass: "td_tables" }, [
                                _vm._v(_vm._s(item.name)),
                              ]),
                            ]
                          )
                        }),
                        0
                      )
                    }),
                    0
                  ),
                ]
              ),
              _c("FooterReport"),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "h1",
        {
          staticStyle: {
            margin: "0",
            "text-align": "left",
            "font-weight": "bold",
            width: "70%",
          },
        },
        [_vm._v(" Relatório de abertura de push notification ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("th", [_vm._v("Dia")]),
      _c("th", [_vm._v("Pushes")]),
      _c("th", [_vm._v("Envios")]),
      _c("th", [_vm._v("Aberturas")]),
      _c("th", [_vm._v("Taxa de abertura")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("th", [_vm._v("Dia")]),
      _c("th", [_vm._v("Pushes")]),
      _c("th", [_vm._v("Envios")]),
      _c("th", [_vm._v("Aberturas")]),
      _c("th", [_vm._v("Taxa de abertura")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }