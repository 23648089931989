var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "md-layout" },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass:
              "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
          },
          [_c("Spinner")],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.loading,
                expression: "!loading",
              },
            ],
            staticClass: "md-layout-item md-size-100",
          },
          [
            _c("FilterSelector", {
              attrs: {
                disableSearch: !this.selectedTopic,
                disablePrint: this.tablePushNotification.length === 0,
                filterForReport: true,
                buttonsToDisplay: [
                  _vm.buttonEnum.YESTERDAY,
                  _vm.buttonEnum.SEVEN_DAYS,
                  _vm.buttonEnum.MONTH_TO_DATE,
                  _vm.buttonEnum.CUSTOM,
                ],
              },
              on: {
                searchData: function ($event) {
                  return _vm.getPushNotificationOpeningsReport()
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "filters",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "md-layout" },
                        [
                          _c(
                            "h3",
                            {
                              staticClass: "md-layout-item md-size-100",
                              staticStyle: {
                                "font-size": "1rem",
                                "text-align": "left",
                                margin: "25px 0 10px 0",
                                padding: "0",
                                "font-weight": "100",
                              },
                            },
                            [_vm._v(" Selecione o tópico ")]
                          ),
                          _c(
                            "el-select",
                            {
                              staticStyle: { margin: "0 !important" },
                              attrs: { placeholder: "Tópico de notificação" },
                              model: {
                                value: _vm.selectedTopic,
                                callback: function ($$v) {
                                  _vm.selectedTopic = $$v
                                },
                                expression: "selectedTopic",
                              },
                            },
                            _vm._l(_vm.listOfTopics, function (topic, index) {
                              return _c(
                                "el-option",
                                { key: index, attrs: { value: topic } },
                                [_vm._v(" " + _vm._s(topic) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "other-options",
                  fn: function () {
                    return [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-info",
                          attrs: {
                            disabled: _vm.tablePushNotification.length === 0,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.printReport()
                            },
                          },
                        },
                        [_vm._v(" Imprimir ")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: this.tablePushNotification.length > 0,
                expression: "this.tablePushNotification.length > 0",
              },
            ],
            staticClass:
              "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-30 md-xlarge-size-30 principal",
          },
          [
            _c(
              "md-card",
              [
                _c("md-card-header", [
                  _c(
                    "h3",
                    {
                      staticStyle: {
                        margin: "10px 0 0 5px !important",
                        "text-align": "center",
                      },
                    },
                    [_vm._v(" ESTATÍSTICAS GERAIS ")]
                  ),
                ]),
                _c(
                  "md-card-content",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: this.tablePushNotification.length > 0,
                        expression: "this.tablePushNotification.length > 0",
                      },
                    ],
                    staticClass: "md-content-dashboard",
                  },
                  [
                    _c("div", { staticClass: "md-layout" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "md-layout-item md-xsmall-size-100 md-small-size-25 md-large-size-100 md-xlarge-size-100",
                          staticStyle: { "text-align": "center" },
                        },
                        [
                          _c("h4", [_vm._v("Total de envios")]),
                          _c("h3", [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatNumber")(this.totalPushesSent)
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "md-layout-item md-xsmall-size-100 md-small-size-25 md-large-size-100 md-xlarge-size-100",
                          staticStyle: { "text-align": "center" },
                        },
                        [
                          _c("h4", [_vm._v("Total de aberturas")]),
                          _c("h3", [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatNumber")(this.totalPushesOpened)
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "md-layout-item md-xsmall-size-100 md-small-size-25 md-large-size-100 md-xlarge-size-100",
                          staticStyle: { "text-align": "center" },
                        },
                        [
                          _c("h4", [_vm._v("Taxa de abertura")]),
                          _c("h3", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (
                                    this.percentOpeningTotalPushes * 100
                                  ).toFixed(2)
                                ) +
                                "% "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "md-layout-item md-xsmall-size-100 md-small-size-25 md-large-size-100 md-xlarge-size-100",
                          staticStyle: { "text-align": "center" },
                        },
                        [
                          _c("h4", [_vm._v("Total de pushes")]),
                          _c("h3", [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatNumber")(
                                  this.totalPushesRegistred
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: this.tablePushNotification.length > 0,
                expression: "this.tablePushNotification.length > 0",
              },
            ],
            staticClass:
              "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-70 md-xlarge-size-70 principal",
          },
          [
            _c(
              "md-card",
              [
                _c("md-card-header", [
                  _c(
                    "h3",
                    {
                      staticStyle: {
                        margin: "10px 0 0 5px !important",
                        "text-align": "center",
                      },
                    },
                    [_vm._v(" GRÁFICO DE ABERTURAS POR DIA ")]
                  ),
                ]),
                _c(
                  "md-card-content",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: this.tablePushNotification.length > 0,
                        expression: "this.tablePushNotification.length > 0",
                      },
                    ],
                    staticClass: "md-content-dashboard",
                  },
                  [
                    _c("div", { staticClass: "md-layout" }, [
                      _c("div", {
                        staticClass:
                          "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 row-icon-dashboard platform-data-container",
                        staticStyle: { padding: "0" },
                        attrs: { id: "chart-openings-per-category" },
                      }),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: this.tablePushNotification.length > 0,
                expression: "this.tablePushNotification.length > 0",
              },
            ],
            staticClass:
              "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 principal",
            staticStyle: { margin: "auto" },
          },
          [
            _c(
              "md-card",
              [
                _c(
                  "md-card-header",
                  { staticClass: "md-card-header-icon md-card-header-blue" },
                  [_c("h3", [_vm._v("ABERTURAS POR DIA")])]
                ),
                _c("md-card-content", [
                  _c(
                    "table",
                    {
                      staticClass: "table_main_1",
                      staticStyle: { "overflow-x": "scroll" },
                    },
                    [
                      _c("thead", { staticStyle: { "text-align": "left" } }, [
                        _c("th", [_vm._v("DATA")]),
                        _c("th", [_vm._v("PUSHES")]),
                        _c("th", [_vm._v("ENVIOS")]),
                        _c("th", [_vm._v("ABERTURAS")]),
                        _c("th", [_vm._v("TAXA DE ABERTURA")]),
                      ]),
                      _c(
                        "tbody",
                        _vm._l(
                          _vm.tablePushNotification,
                          function (item, index) {
                            return _c(
                              "tr",
                              { key: index, staticClass: "tr_tables" },
                              [
                                _c("td", { staticClass: "td_tables" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        new Date(item.date).toLocaleDateString(
                                          "pt-BR"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("td", { staticClass: "td_tables" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatNumber")(
                                          item.pushes_registred
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("td", { staticClass: "td_tables" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatNumber")(item.pushes_sent)
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("td", { staticClass: "td_tables" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatNumber")(
                                          item.pushes_opened
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("td", { staticClass: "td_tables" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(item.percent_openings.toFixed(2)) +
                                      "% "
                                  ),
                                ]),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ]
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
          },
          [
            _c(
              "md-card",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: this.showMsg,
                    expression: "this.showMsg",
                  },
                ],
                staticStyle: { "text-align": "center" },
              },
              [
                _c("md-card-content", [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v("Sem informações para serem exibidas."),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c("TemplateReportPushNotificationOpenings", {
          attrs: {
            id: "report",
            nameRadio: this.radio.name,
            logoRadio: this.radio.logo,
            periodStart: this.periodStart,
            periodEnd: this.periodEnd,
            topic: this.selectedTopic,
            parentName: this.radio.parent_name,
            radiosConsolidated: [],
            data: {
              tablePushNotification: this.tablePushNotification,
              totalPushesOpened: this.totalPushesOpened,
              totalPushesRegistred: this.totalPushesRegistred,
              totalPushesSent: this.totalPushesSent,
              percentOpeningTotalPushes: this.percentOpeningTotalPushes,
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }