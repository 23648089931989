<template>
	<div>
		<div class="md-layout">
			<div
				v-show="loading"
				class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
			>
				<Spinner />
			</div>
			<div v-show="!loading" class="md-layout-item md-size-100">
				<FilterSelector
					@searchData="getPushNotificationOpeningsReport()"
					:disableSearch="!this.selectedTopic"
					:disablePrint="this.tablePushNotification.length === 0"
					:filterForReport="true"
					:buttonsToDisplay="[
						buttonEnum.YESTERDAY,
						buttonEnum.SEVEN_DAYS,
						buttonEnum.MONTH_TO_DATE,
						buttonEnum.CUSTOM,
					]"
				>
					<template #filters>
						<div class="md-layout">
							<h3
								class="md-layout-item md-size-100"
								style="
									font-size: 1rem;
									text-align: left;
									margin: 25px 0 10px 0;
									padding: 0;
									font-weight: 100;
								"
							>
								Selecione o tópico
							</h3>
							<el-select
								v-model="selectedTopic"
								style="margin: 0 !important"
								placeholder="Tópico de notificação"
							>
								<el-option
									v-for="(topic, index) in listOfTopics"
									:key="index"
									:value="topic"
								>
									{{ topic }}
								</el-option>
							</el-select>
						</div>
					</template>
					<template #other-options>
						<md-button
							@click="printReport()"
							:disabled="tablePushNotification.length === 0"
							class="md-info"
						>
							Imprimir
						</md-button>
					</template>
				</FilterSelector>
			</div>
			<div
				v-show="this.tablePushNotification.length > 0"
				class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-30 md-xlarge-size-30 principal"
			>
				<md-card>
					<md-card-header>
						<h3 style="margin: 10px 0 0 5px !important; text-align: center">
							ESTATÍSTICAS GERAIS
						</h3>
					</md-card-header>
					<md-card-content
						class="md-content-dashboard"
						v-show="this.tablePushNotification.length > 0"
					>
						<div class="md-layout">
							<div
								class="md-layout-item md-xsmall-size-100 md-small-size-25 md-large-size-100 md-xlarge-size-100"
								style="text-align: center"
							>
								<h4>Total de envios</h4>
								<h3>{{ this.totalPushesSent | formatNumber }}</h3>
							</div>
							<div
								class="md-layout-item md-xsmall-size-100 md-small-size-25 md-large-size-100 md-xlarge-size-100"
								style="text-align: center"
							>
								<h4>Total de aberturas</h4>
								<h3>{{ this.totalPushesOpened | formatNumber }}</h3>
							</div>
							<div
								class="md-layout-item md-xsmall-size-100 md-small-size-25 md-large-size-100 md-xlarge-size-100"
								style="text-align: center"
							>
								<h4>Taxa de abertura</h4>
								<h3>
									{{ (this.percentOpeningTotalPushes * 100).toFixed(2) }}%
								</h3>
							</div>
							<div
								class="md-layout-item md-xsmall-size-100 md-small-size-25 md-large-size-100 md-xlarge-size-100"
								style="text-align: center"
							>
								<h4>Total de pushes</h4>
								<h3>{{ this.totalPushesRegistred | formatNumber }}</h3>
							</div>
						</div>
					</md-card-content>
				</md-card>
			</div>
			<div
				v-show="this.tablePushNotification.length > 0"
				class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-70 md-xlarge-size-70 principal"
			>
				<md-card>
					<md-card-header>
						<h3 style="margin: 10px 0 0 5px !important; text-align: center">
							GRÁFICO DE ABERTURAS POR DIA
						</h3>
					</md-card-header>
					<md-card-content
						class="md-content-dashboard"
						v-show="this.tablePushNotification.length > 0"
					>
						<div class="md-layout">
							<div
								class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 row-icon-dashboard platform-data-container"
								style="padding: 0"
								id="chart-openings-per-category"
							></div>
						</div>
					</md-card-content>
				</md-card>
			</div>

			<div
				v-show="this.tablePushNotification.length > 0"
				class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 principal"
				style="margin: auto"
			>
				<md-card>
					<md-card-header class="md-card-header-icon md-card-header-blue">
						<h3>ABERTURAS POR DIA</h3>
					</md-card-header>

					<md-card-content>
						<table class="table_main_1" style="overflow-x: scroll">
							<thead style="text-align: left">
								<th>DATA</th>
								<th>PUSHES</th>
								<th>ENVIOS</th>
								<th>ABERTURAS</th>
								<th>TAXA DE ABERTURA</th>
							</thead>
							<tbody>
								<tr
									class="tr_tables"
									v-for="(item, index) in tablePushNotification"
									:key="index"
								>
									<td class="td_tables">
										{{ new Date(item.date).toLocaleDateString("pt-BR") }}
									</td>
									<td class="td_tables">
										{{ item.pushes_registred | formatNumber }}
									</td>
									<td class="td_tables">
										{{ item.pushes_sent | formatNumber }}
									</td>
									<td class="td_tables">
										{{ item.pushes_opened | formatNumber }}
									</td>
									<td class="td_tables">
										{{ item.percent_openings.toFixed(2) }}%
									</td>
								</tr>
							</tbody>
						</table>
					</md-card-content>
				</md-card>
			</div>
			<div
				class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
			>
				<md-card style="text-align: center" v-show="this.showMsg">
					<md-card-content>
						<label for="">Sem informações para serem exibidas.</label>
					</md-card-content>
				</md-card>
			</div>
			<TemplateReportPushNotificationOpenings
				id="report"
				:nameRadio="this.radio.name"
				:logoRadio="this.radio.logo"
				:periodStart="this.periodStart"
				:periodEnd="this.periodEnd"
				:topic="this.selectedTopic"
				:parentName="this.radio.parent_name"
				:radiosConsolidated="[]"
				:data="{
					tablePushNotification: this.tablePushNotification,
					totalPushesOpened: this.totalPushesOpened,
					totalPushesRegistred: this.totalPushesRegistred,
					totalPushesSent: this.totalPushesSent,
					percentOpeningTotalPushes: this.percentOpeningTotalPushes,
				}"
			/>
		</div>
	</div>
</template>

<script>
import Spinner from "../../../components/Icons/Spinner";
import FilterSelector from "../Components/FilterSelector/FilterSelector.vue";
import filters from "../Components/FilterSelector/filters";
import buttonEnum from "../Components/FilterSelector/buttonEnum";
import { DatePicker } from "element-ui";
import jwt_decode from "jwt-decode";
import models from "../../../routes/models.js";
import numeral from "numeral";
import Swal from "sweetalert2";
import Vue from "vue";
import TemplateReportPushNotificationOpenings from "./templates/TemplateReportPushNotificationOpenings.vue";

import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);

numeral.locale("pt-br");
Vue.filter("formatNumber", function (value) {
	return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
});

export default {
	components: {
		[DatePicker.name]: DatePicker,
		Spinner,
		TemplateReportPushNotificationOpenings,
		FilterSelector,
	},
	data() {
		return {
			buttonEnum: buttonEnum,
			loading: false,
			disabledButton: true,
			tablePushNotification: [],
			listOfTopics: [],
			selectedTopic: null,
			periodStart: null,
			periodEnd: null,
			totalPushesRegistred: 0,
			totalPushesSent: 0,
			totalPushesOpened: 0,
			percentOpeningTotalPushes: 0,
			radio: jwt_decode(localStorage.getItem("APP_01")).radio,
			appKey: jwt_decode(localStorage.getItem("APP_01")).radio.key,
			showMsg: false,
		};
	},
	methods: {
		getPushNotificationTopics() {
			models()
				.get(`report/getPushNotificationTopicsForReport?app_key=${this.appKey}`)
				.then((res) => {
					this.listOfTopics = res.data.map((e) => e.topic);
					this.loading = false;
				})
				.catch((err) => {
					console.log(err);
					Swal(
						"Ops!",
						"Houve algum erro ao recuperar os tópicos de notificação. Caso o erro persistir, entre em contato com o suporte",
						"error"
					);
				});
		},
		getPushNotificationOpeningsReport() {
			const requestQuery = `app_key=${this.appKey}&topic=${
				this.selectedTopic
			}&start_date=${this.formatDateToSQL(filters.startDate)} 00:00:00
				&end_date=${this.formatDateToSQL(filters.endDate)} 23:59:59`;
			this.loading = true;
			this.showMsg = false;
			this.periodStart = new Date(filters.startDate).toLocaleDateString(
				"pt-BR"
			);
			this.periodEnd = new Date(filters.endDate).toLocaleDateString("pt-BR");
			this.tablePushNotification = [];
			models()
				.get(`report/getPushNotificationOpeningsReport?${requestQuery}`)
				.then((res) => {
					this.tablePushNotification = res.data;
					this.totalPushesRegistred = this.tablePushNotification.reduce(
						(accumulator, a) => accumulator + a.pushes_registred,
						0
					);
					this.totalPushesSent = this.tablePushNotification.reduce(
						(accumulator, a) => accumulator + a.pushes_sent,
						0
					);
					this.totalPushesOpened = this.tablePushNotification.reduce(
						(accumulator, a) => accumulator + a.pushes_opened,
						0
					);
					this.percentOpeningTotalPushes = (
						this.totalPushesOpened / this.totalPushesSent
					).toFixed(2);
					this.loading = false;
					if (this.tablePushNotification.length === 0) {
						this.showMsg = true;
					} else {
						this.buildChartOpeningsPerDay();
					}
				})
				.catch((err) => {
					console.log(err);
					Swal(
						"Ops!",
						"Houve algum erro ao recuperar os dados do relatório de push notification. Caso o erro persistir, entre em contato com o suporte",
						"error"
					);
				});
		},
		buildChartOpeningsPerDay() {
			Highcharts.chart("chart-openings-per-category", {
				chart: {
					type: "spline",
				},

				exporting: {
					enabled: false, // Desabilitar a exportação de botões
				},
				title: {
					text: "",
				},
				xAxis: {
					categories: this.tablePushNotification.map((e) =>
						new Date(e.date).toLocaleDateString("pt-BR")
					),
					title: {
						text: "Data",
					},
					labels: {
						padding: 20,
					},
				},
				yAxis: [
					{
						title: {
							text: "Envios de push",
						},
					},
					{
						opposite: true,
						title: {
							text: "Taxa de abertura (em %)",
						},
					},
				],
				series: [
					{
						name: "Enviados",
						data: this.tablePushNotification.map((e) => e.pushes_sent),
						yAxis: 0,
						color: "#5BB643",
					},
					{
						name: "Abertos",
						data: this.tablePushNotification.map((e) => e.pushes_opened),
						yAxis: 0,
						color: "#000000",
					},
					{
						name: "Taxa de abertura",
						data: this.tablePushNotification.map((e) => e.percent_openings),
						yAxis: 1,
						color: "#D30000",
						dashStyle: "LongDash",
					},
				],
			});
		},
		formatDateToSQL(date) {
			return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
		},
		printReport() {
			window.print();
		},
	},

	computed: {
		pickerOptionsStart() {
			return {
				disabledDate: (time) => {
					if (!this.endDate) {
						return false;
					}
					const maxDate = this.endDate ? new Date(this.endDate) : new Date();
					maxDate.setDate(maxDate.getDate() - 6); // Data máxima permitida (7 dias atrás)

					return time.getTime() > maxDate;
				},
			};
		},
		pickerOptionsEnd() {
			return {
				disabledDate: (time) => {
					const minDate = this.startDate
						? new Date(this.startDate)
						: new Date();
					minDate.setDate(minDate.getDate() + 7);

					const maxDate = new Date(this.startDate);
					maxDate.setDate(maxDate.getDate() + 30);

					return time.getTime() < minDate || time.getTime() > maxDate;
				},
			};
		},
	},
	mounted() {
		this.getPushNotificationTopics();
		this.$root.$on("generateReport", () => {
			this.getPushNotificationOpeningsReport();
		});
	},
	watch: {
		endDate() {
			this.disabledButton = !(this.startDate !== null && this.endDate !== null);
		},
		startDate() {
			this.disabledButton = !(this.startDate !== null && this.endDate !== null);
		},
		tablePushNotification() {
			this.btnImprimir = this.tablePushNotification.length <= 0;
		},
	},
};
</script>
<style>
#chartDivOsPush {
	width: 100%;
	height: 300px;
}

#chart-openings-push {
	box-shadow: none;
	width: 80%;
}

#chart-openings-per-category {
	height: 500px;
}

#report {
	position: absolute;
	top: -9999px;
	left: -9999px;
	visibility: hidden;
}

@media print {
	#report {
		position: static;
		top: 0;
		left: 0;
		z-index: 0;
		width: 100%;
		height: 100%;
		visibility: visible;
	}

	.principal {
		display: none;
	}
}
</style>
